import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase/config";
import { useState, useEffect } from "react";
import "./trackorder.scss";
import { collection, query, doc, onSnapshot } from "firebase/firestore";
import Map from "../components/Map";

const TrackOrder = () => {
  const params = useParams();
  const { id } = params;
  const [trackingData, setTrackingData] = useState("");
  useEffect(() => {
    try {
      const q = query(collection(db, "tracking"));

      onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("empty");
        } else {
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          const tracking = data.find((item) => item.id === id);
          setTrackingData(tracking);
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  }, [id]);

  return (
    <div>
      <div
        className="breadcrumb-option set-bg"
        data-setbg="img/breadcrumb-bg.jpg"
        style={{
          backgroundImage: `url(https://images.unsplash.com/photo-1485575301924-6891ef935dcd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb__text">
                <h2>Track Your Order</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding id-container">
        <div className="container">
          <div className="tracking-div">
            <h3 className="track-id-heading">Order ID: {id}</h3>

            <div className="percentage">
              <h5 style={{ marginBottom: "1rem" }}>Percentage</h5>
              <div className="percentage__bar">
                <p className="percentage__bar__item" style={{marginBottom: 0}}>
                  {trackingData.percentage}%
                </p>
                {/* style percentage progress base on percentage data */}
                <div
                  className="percentage__bar__item"
                  style={{ border: "2px solid #f7bdaa", borderRadius: "5px" }}
                >
                  <div
                    className="percentage__bar__item__line"
                    style={{
                      width: `${trackingData.percentage}%`,
                      background: "grey",
                      height: "1.5rem",
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <h4 className="track-heading" style={{ marginTop: "2rem" }}>
              Delivery Info:
            </h4>
            <p className="delivery_details">{trackingData?.clientDetails}</p>

            {trackingData &&
              trackingData.extra.map((item, index) => (
                <div key={index} className="tracking-info-extra">
                  <p>
                    Time: <span>{item.pTime}</span>{" "}
                  </p>
                  <p>
                    Date: <span>{item.pDate}</span>{" "}
                  </p>
                  <p>
                    Comment: <span>{item.pComment}</span>
                  </p>
                </div>
              ))}
          </div>

          <div className="tracking-div">
            <h4 className="track-heading">Shipment History</h4>

            <table>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Location</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {trackingData &&
                  trackingData.shipping.map((item, index) => (
                    <tr key={index}>
                      <td>{item.time}</td>
                      <td>{item.location}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
                {/* {trackingData?.shipping.length === 0 ?
                <tr>
                  <td colSpan="5">No Location hav been added</td>
                </tr> :
                null
              } */}
              </tbody>
            </table>
          </div>

          <div className="tracking-div">
            <h4 className="track-heading">Packages</h4>

            <table>
              <thead>
                <tr>
                  <th>Qty.</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Length</th>
                  <th>Width</th>
                </tr>
              </thead>
              <tbody>
                {trackingData &&
                  trackingData.packaging.map((item, index) => (
                    <tr key={index}>
                      <td>{item.PQuantity}</td>
                      <td>{item.PType}</td>
                      <td>{item.PDesc}</td>
                      <td>{item.PLength}</td>
                      <td>{item.PWidth}</td>
                    </tr>
                  ))}
                {/* {trackingData?.packaging.length === 0 ?
                <tr>
                  <td colSpan="5">No Package hav been added</td>
                </tr> :
                null
              } */}
              </tbody>
            </table>
          </div>

          {trackingData.mapLink && (
            <div className="map-div">
              <Map googleMapLink={trackingData.mapLink} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;
