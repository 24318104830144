/* eslint-disable react/style-prop-object */
import React from "react";

const Map = ({ googleMapLink }) => {
  function getIframeSrc(iframeHtml) {
    // Option 1: Using a DOMParser
    const parser = new DOMParser();
    const doc = parser.parseFromString(iframeHtml, "text/html");
    const iframe = doc.querySelector("iframe");

    console.log(iframe);

    return iframe ? iframe.getAttribute("src") : null;

    // Option 2 (Regex-based) for quick extraction:
    // const regex = /<iframe[^>]*src="([^"]+)"/;
    // const match = regex.exec(iframeHtml);
    // return match ? match[1] : null;
  }

  const src = getIframeSrc(
    googleMapLink ||
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11426809.369690305!2d-7.982064924733481!3d45.63579083081524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd54a02933785731%3A0x6bfd3f96c747d9f7!2sFrance!5e0!3m2!1sen!2sng!4v1734957551114!5m2!1sen!2sng" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
  );
  return (
    <div>
      <iframe
        title="Google Maps Location"
        src={src}
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
